.generate-listing{
    .stock-register{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .stock-filter{
        .button-submit{
            margin-left: 200px;
            // top: 28px;
        }
    }
    width: 100%;
    min-width: 100%;
    table-layout: auto;
}