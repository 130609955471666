.insurance-form {
  .detail-element {
    margin: 10px 0px;
    span {
      margin-bottom: 10px;
    }
  }

  .detail-wrapper {
    margin-bottom: 16px;
    // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); //7.009px 7.009px 5.006px rgba(175,194,203,.251)
    border-radius: 3px;
    // padding: 15px 15px 8px;
    h3 {
      font-weight: 700;
      font-size: 14px;
      color: #000;
      span {
        font-weight: 400;
        margin-left: 5px;
        &.event {
          font-weight: 600;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }
    .ant-ag {
      font-size: 14px;
      padding: 2px 10px;
    }
  }
  .page-content {
    .page-heading {
      margin: 20px 0px;
      font-size: 20px;
    }
  }

  .requests-list {
    // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); //7.009px 7.009px 5.006px rgba(175,194,203,.251)

    .ant-list-item {
      margin: 0px 10px;
    }
  }

  // .vehicle-card {
  //   @media only screen and (min-width: 600px) {
  //     justify-content: space-between;
  //     display: flex;
  //     /* align-items: center; */
  //     /* box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); */
  //     /* padding: 0px 20px; */
  //     flex-direction: row-reverse;
  //     margin: 30px 0px;

  //     .vehicle-image-wrapper {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       img {
  //         margin: 10px;
  //         width: 350px;
  //       }
  //     }

  //     // display: flex;

  //     // justify-content: flex-start;

  //     h1.second-line {
  //       color: #7d7d7d;
  //       font-size: 70px;
  //     }
  //   }
  // }

  .page-content {
    .split-container {
      display: flex;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      .vehicle-card {
        margin-bottom: 20px;
        padding: 20px;
        // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        // margin: 15px 0;
        border: 1px solid #efefef;
        color: #49535d;

        .detail-wrapper {
          .detail-element {
            margin: 20px 0px;
            h3 {
              margin: 10px 0px;
            }
          }
        }
        .document-status{
          margin: 10px 0px;
        }
      }

      > .left {
        width: 300px;

        @media only screen and (max-width: 768px) {
        }
      }
      .right {
        margin: 0px 0px 0px 20px;
        flex-grow: 1;
        // padding: 20px;
        /* box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); */
        // border: 1px solid #efefef;
        color: #49535d;

        .vehicle-card {
          // margin: 10px 0px;
        }

        .cancel-button{
          margin-left: 10px;
        }
      }
    }
  }
}
