.booking-detail {
  .request-status {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  h3 {
    i {
      font-weight: 400;
      font-style: normal;
      font-size: 15px;
      color: brown;
    }
  }

  .request-status {
    margin: 10px 0px;
  }

  .page-content {
    .split-container {
      display: flex;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      > .left {
        width: 300px;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }

      
      }
      .right {
        margin: 0px 20px;
        flex-grow: 1;
        color: #49535d;
        @media only screen and (max-width: 768px) {
          margin: 10px 0px;
        }

        .ant-list-items {
          .vehicle-item {
            padding: 10px 0px;
            border-bottom: 1px solid #ececec;
          }
        }
      }
    }
  }

}
