.compare-gst {
    .main-content {
        display: flex;
        justify-content: space-between;
        .vehicle-card {
            margin-right: 15px;
        }
    }

    table {
        tr {
            th {
                white-space: nowrap;
            }
            td {
                white-space: nowrap;
            }
        }
    }

    .missing {
        display: flex;
        flex-wrap: wrap;
    }
    span.space {
        padding: 10px;
    }
}
