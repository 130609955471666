.request-detail {
  h3 {
    i {
      font-weight: 400;
      font-style: normal;
      font-size: 15px;
      color: brown;
    }
  }

  .request-status {
    margin: 10px 0px;

    @media only screen and (max-width: 768px) {

      display: flex;
      flex-direction: column;
      
    }
  }

  .page-content {
    .split-container {
      display: flex;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      .vehicle-card {
        padding: 20px;

        &.left-detail {
          @media only screen and (min-width: 768px) {
            width: 300px;
          }
        }

        &.pricing {
          // display: flex;
          // align-items: flex-end;

          .document-status {
            margin-right: 20px;
          }
        }
        // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        // margin: 15px 0;
        border: 1px solid #efefef;
        color: #49535d;

        .detail-wrapper {
          .detail-element {
            margin: 20px 0px;
            h3,
            h2 {
              margin: 10px 0px;
            }
          }
        }
      }

      > .left {
        @media only screen and (min-width: 768px) {
          width: 300px;
        }
      }
      .right {
        // margin: 0px 0px 0px 20px;

        @media only screen and (max-width: 768px) {
          margin: 0px;
          margin-top: 10px;
        }

        flex-grow: 1;
        // padding: 20px;
        /* box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); */
        // border: 1px solid #efefef;
        color: #49535d;

        .vehicle-card {
          margin: 10px 0px;
        }
      }
    }
  }

  .allocation-request {
    .left {
    }
    .right {
      flex-basis: 30%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}
