.enquiry-detail {
    .enquiry-card {
        margin-bottom: 10px;
        p {
            margin: 0px;
        }
    }
    .score-card {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: 1px solid whitesmoke;
        margin: 8px 3px;
        padding: 8px 8px;
        box-shadow: 0px 1px 3px #d6d6d6;
    }

    .right {
        .card {
            // margin-top: 0px;
        }
    }
}
