
.high-priority{
    padding: 0px 10px;
    
    .page-content{

     
    
    }
}
