/**
 *Status Change Component
 */

.status-change {
    .actions {
        button {
            span {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }
}

.ant-modal {
    .ant-modal-content {
        .ant-modal-body {
            .status-modal {
                display: flex;

                .form-section {
                    width: 40%;

                    // padding-right: 10px;
                }

                .history {
                    padding-top: 48px;

                    width: 60%;

                    padding-left: 25px;

                    .details {
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}
