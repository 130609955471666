.generate {
    .page-header {
        margin: 10px;
        &.push-end {
            // justify-content: flex-end;
        }
        .import-form {
            display: flex;
        }

        h4 {
            text-transform: capitalize;
        }

        .page-actions {
            display: flex;
            gap: 10px;
        }
    }
}
