
// .stock-transfer-details{
//     .qr-record {
            
//         height: auto;
//         margin: 0 auto;
//         max-width: 64;
//         width: 100%;
//         margin-right: 299px;

    

    
//         // @media print {
//         //     width: 30px;
//         //     display: flex;
//         //     justify-content: center;
//         //     align-items: center;
//         // }
    
//         // .barcode-content {
//         //     flex-direction: column;
//         //     margin: 0px 10px;
//         //     align-items: center;
//         //     display: flex;
//         //     p {
//         //         &.label-title {
//         //             font-weight: bold;
//         //             // transform: rotate(270deg);
//         //         }
//         //         &.label-description {
//         //             margin-right: 20px;
//         //             // transform: rotate(270deg);
//         //         }
//         //     }
//         // }
    
//     }

// }

.print-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .print-row > div {
    flex: 0 0 calc(33.33% - 20px); /* Adjust the width as per your requirements */
  }

  @media print {
    .hide-on-print {
      display: none !important;
    }
  }