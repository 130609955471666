.details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;

    .detail-card {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
    }
    .guest-details {
        display: flex;
        flex-direction: column;
    }
    .guest-details-info {
        display: flex;
        justify-content: space-between;
        gap: 3rem;
    }
    // .detaile-element {
    //     width: 26%;
    // }
    .details {
        color: #4d4d4d;
    }
    .detail-wrapper {
        display: flex;
        flex-wrap: wrap;

        .ant-row {
            margin-top: 8px;
        }
        @media only screen and (max-width: 768px) {
            display: flex;
            flex-basis: 100%;
        }
    }
    .detail-element {
        width: 42%;
        margin-left: 15px;
    }
}
