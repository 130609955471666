.preference-detail {

    .page-content {
        display: flex;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        .right-content {
            flex-grow: 1;
        }

        .vehicle-thumbnail {
            // display: flex;
            // justify-content: space-between;

            @media only screen and (min-width: 768px) {
                width: 300px;
            }

            margin-right: 10px;

            img {
                width: 100%;

                @media only screen and (min-width: 768px) {
                    width: 120px;
                }
                width: 100px;
            }
            .thumbnail {
                margin-bottom: 10px;
            }

            h4.ant-typography {
                margin: 0px;
            }
            .size-hint {
                margin-bottom: 10px;
            }
        }
    }

    margin: 0px 10px;
    h5 {
        i {
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            color: brown;
            display: block;
        }
    }

    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .page-content {
        .split-container {
            display: flex;
            @media only screen and (max-width: 768px) {
                flex-direction: column;
            }

            > .left {
                @media only screen and (min-width: 768px) {
                    width: 300px;
                }

                @media only screen and (max-width: 768px) {
                }
            }
            > .right {
                @media only screen and (min-width: 768px) {
                    margin: 0px 0px 0px 20px;
                }

                flex-grow: 1;
                // padding: 20px;
                /* box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); */
                // border: 1px solid #efefef;
                color: #49535d;

                .vehicle-card {
                    margin: 10px 0px;
                }
            }
        }
    }

    .allocation-request {
        .left {
            h3.variant {
                display: inline;
            }
        }
        .right {
            flex-basis: 30%;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            &.card-buttons {
                display: flex;
                align-items: center;

                @media only screen and (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;

                    button,
                    .ant-tag,
                    a {
                        margin: 10px 0px 10px 0px;
                    }
                }

                button {
                    margin-right: 10px;
                }
            }
        }
    }
}
