
.manage-bookings{


  .page-header{
    display: flex;

    .ant-dropdown-trigger{
      span{
        text-transform: capitalize;
      }
    }

    .table-hint{
      display: flex;

      button{
        margin: 0px 4px;
      }

      .page-actions{
        display: flex;

        .button-container{
          display: flex;
        }
      }
    }


    
  }

  .booking-upload-form{
    display: flex;
    justify-content: space-between;
    padding: 21px 0px;
    align-items: center;
  }

}