.bulk-kec-change {
    margin-top: 20px;
  
    .page-header {
        display: flex;
        flex-direction: column;
        align-items: unset;
  
        .page-actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}    