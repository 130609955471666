.stock-detail {
    .request-status {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  
    h3 {
      i {
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        color: brown;
      }
    }
  
    .request-status {
      margin: 10px 0px;
    }
  
    .page-content {
      .split-container {
        display: flex;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }
  
        > .left {
          width: 300px;
  
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
  
          .vehicle-card {
            padding: 20px;
            // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
            // margin: 15px 0;
            border: 1px solid #efefef;
            color: #49535d;
  
            .detail-wrapper {
              .detail-element {
                margin: 20px 0px;
                h3 {
                  margin: 10px 0px;
                }
              }
            }
          }
        }
        >.right {
          margin: 0px 20px;
          flex-grow: 1;
          color: #49535d;
          @media only screen and (max-width: 768px) {
            margin: 10px 0px;
          }
  
          .ant-list-items {
            .vehicle-item {
              padding: 10px 0px;
              border-bottom: 1px solid #ececec;
            }
          }
        }
      }
    }

  }
  