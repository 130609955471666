.buttons-set {
    display: flex;

    .buttons {

        display: flex;

        .edit-discount-div {

            display: flex;

            .lateDiscount {
                float: 'right'
            }

            .editBill {
                margin-top: '10px'
            }
        }
    }
}