

.payment-card{
    width: 100%;
    .button-group{
        margin-top: 20px;
        display: flex;
        button{
            margin-right:10px;
        }

    }
}