.request-dashboard{
    
    margin: 25px 0px 15px 0px;

    .score{
        // display: flex;

        .card:first-child {
            margin-top: 15px;
        }

        .card{
            margin:15px 15px 10px 0px;
        }

        .dashboard-item{
            
        }
    

    }


    .vehicle-card{

        h3{
            text-transform: capitalize;
        }

        .vehicle-header{
            h1{
                margin-bottom: 0px;
                text-transform: capitalize;
            }
        }

    }


    .capitalize{
        text-transform: capitalize;
    }

    
    .count-bubble{
        color: #8c8c8c;
        background: #f0f0f0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        font-size: 0.8em;
        padding: 3px;
        font-weight: bold;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
    }

    .status-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        .card:first-child {
            margin-top: 15px;
        }

        .ant-list-item{
            padding: 4px ​0px;
        }

        h5{
            margin-bottom: 1em;
        }
    }

    .status-card{
        min-width: 210px;
        max-width: 210px;
        margin-right: 0.8em;

        &:last-child{
            margin-right: 0;
        }

        p{
            font-size: 0.9em;
        }

        a{
            color: #595959;
            font-weight: bold;
            
            &:hover{
               color: #8c8c8c;
            }


        }
       
        .ant-statistic-content{
            display: none;
            font-size: 24px !important;
            margin: 0 0 5px 0 !important;
        }

        .ant-statistic-content-value-int{
            margin-top: -200px;
        }
    }

    @media screen and (max-width: 768px) {
        
        .status-card{
            min-width: 98%;
            max-width: 98%;  
        }
        .status-link-container{
            display: grid;
            grid-template-columns: 1fr;
        }

    }

}