.booking-tracker {
    // width: 80%;
    .page-header{
        @media only screen and (max-width: 768px) {
            display: grid;
            grid-template-columns: 60% 40%;
            align-items: baseline;
        }
        .page-actions {
            @media only screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }
            .ant-space {
                @media only screen and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }
                .ant-space-item {
                    .ant-btn {
                        @media only screen and (max-width: 768px) {
                            width: 120px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
    .ant-card {
        // margin-top: 15px;
        margin-top: 70px;
        .ant-card-body{
            padding: 0px!important;
            .statistic-block{     
                           
                // position: absolute;
                // top: -18%;
                width: 100%;
                @media only screen and (max-width: 768px) {
                    top: -49%;
                }
                .ant-row{
                    width: 86%;
                    @media only screen and (max-width: 768px) {
                        width: 100%;
                        margin-top: 12px;
                    }
                }
                .download-button{
                    margin-top: 18px;
                    text-align: right;
                    
                }

            }
            .search-block{
                position: absolute;
                top: -6.5%;
                right: 0;
                width: 22%;
                height: 32px;
                border-radius: 6px;

                @media only screen and (max-width: 768px) {
                    top: -8.7%;
                    left: 0;
                    width: 100%;
                }
            }
            .ant-divider {
                position: absolute;
                top: -44px;
                width: 100% !important;
                min-width: 0 !important;
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                    min-width: 100% !important;
                    top: -48px;
                }
            }
            .ant-table-wrapper {
                .ant-spin-nested-loading {
                    .ant-spin-container{
                        .ant-table {
                            .ant-table-container {
                                .ant-table-header{
                                    //here header #
                                }
                                .virtual-grid{
                                    .virtual-table-cell{
                                        padding: 4px 15px;
                                        // border-right: 1px solid black;
                                        // border-left: 1px solid rgb(179, 179, 179);
                                        // border-bottom:1px solid rgb(179, 179, 179);

                                        
                                        .main-component{
                                            .checkbox-component{
                                                display: none;
                                            }
                                            &:hover {
                                                .index-component {
                                                    display: none;
                                                }
                                                .checkbox-component{
                                                    display: block !important;
                                                }
                                            }
                                        }
                                        .main-component-selected{
                                            .index-component {
                                                display: none;
                                            }
                                            .checkbox-component{
                                                display: block !important;
                                            }
                                        }
                                    }
                                    .virtual-table-cell-last{
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 768px) {
            margin-top: 135%;
        }
    }
}