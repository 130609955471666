.customer-info {
  // padding: 20px;
  // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
  // margin: 15px 0;
  // border: 1px solid #efefef;
  color: #49535d;

  img {
    width: 100px;
  }

  .ant-timeline {
    padding: 20px 10px;
  }

  .detail-wrapper {
    .detail-element {
      // margin: 20px 0px;
      h3 {
        // margin: 10px 0px;
      }
    }
  }
}
