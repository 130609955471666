.generate-listing{
   
    // .stock-filter{
        .button-submit{
            margin-top: 32px;
            // margin-left: 200px;
            // top: 20px;
        }
    // }
    // width: 100%;
    // min-width: 100%;
    // table-layout: auto;
}