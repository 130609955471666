.manage-receipts {
  .page-header {
    .import-form {
      display: flex;
    }

    .left {
    }
    .right {

      display: flex;
      button{
        margin-left: 10px;
        text-transform: capitalize;
      }

    }
  }
}
