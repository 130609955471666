.global-header {
  .layout-content {
    display: flex;
  }

  .left-bar {
    @media only screen and (min-width: 800px) {
      width: 256px;
    }

    .sidebar-container {
      border-right: 1px solid #f0f0f0;
      height: 100vh;

      .intro {
        padding: 5px 16px;
        border-bottom: 1px solid whitesmoke;

        .logo-welcome {
          cursor: pointer;
        }
        small {
          margin: 0px 10px;
        }

        .locations {
          margin: 10px 0px;
          .ant-tag {
            margin-right: 4px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  .right-section {
    width: 100%;
    overflow-x: scroll;

    @media only screen and (min-width: 768px) {
      padding: 10px 10px;
    }
  }

  .fa {
    padding-right: 15px;
  }

  .ant-drawer-content {
    .ant-drawer-body {
      padding: 0px;
    }
  }

  .page-wrapper {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggle-box {
      display: flex;
      align-items: center;
      cursor: pointer;
      h4 {
        margin-bottom: 0px;
      }
    }

    .toggle-menu {
      margin-right: 10px;
      cursor: pointer;
      // padding: 10px;
      // border-radius: 4px;
      // border: 1px solid #dcdcdc;
      margin-right: 10px;
      span {
        margin-right: 10px;
      }
      &:hover {
        border-color: #c1c1c1;
      }
    }
    .show-search {
      margin-right: 10px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        border-color: #c1c1c1;
      }
    }
    .page-menu {
      display: flex;
      align-items: center;

      .profile-avatar {
        margin: 0px 10px;

        &:hover {
          // box-shadow: 0px 0px 13px 2px #e9e9e9;
          // border: 1px solid #dfdfdf;

          cursor: pointer;
        }

        img {
          width: 40px;
          border-radius: 23px;
          margin: 0px 10px;
          object-fit: contain;
          border: 1px solid #e2e2e2;
          height: 40px;
          box-shadow: 0px 0px 8px 2px #d1d1d1;
          // box-shadow: 0px 0px 15px 6px #e9e9e9;
          color: black;
        }
      }

      .branch-selection {
        margin: 0px 10px;
      }
    }

    .header-blk {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .page-heading {
        margin: 2px 0 0 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
      }
    }
    .page-desc {
      margin-bottom: 10px;
    }
  }
}

.drawer-content {
  .ant-drawer-body {
    padding: 0px;
  }
}
