.bill-details {
    .print-block {
        // overflow: hidden;
        // height: 0;
        // width: 0;
        display: none;
    }
    @media print {
        .mustPrint {display:block !important;}
    }
    font-family: "Roboto";
    .detail-element{
        width: "60%";
        h3{
            font-size: 14px;
        }
        span{
            font-size: 12px;
        }
        .title{
            // color: #1890ff;
              
        }
        
        
    }
    table{
        h3{
            text-align: right;
            font-size: 14px;
        }
        .amount{
            text-align: left;
            font-size: 14px;
            // color: red!important;
        }

    }
    
    .ant-table.ant-table-small {
        font-size: 12px;
        
    }
    .ant-table-small .ant-table-thead > tr > th {
            // background-color: #1890ff36;
            padding: 15px 2px;
            align-items: center;
    }
}
.allocation-form .detail-element {
    margin: 8px 0px;
}