.accessory-choices {
    min-height: 100px;
    margin-bottom: 20px !important;

    .add-button {
        // float: right;
        position: absolute;
        right: 0px;
        top: 10px;
        width: 16%;
    }

    .ant-table-cell {
        white-space: nowrap;
    }

    .add-discount-button {
        position: absolute;
        right: 20%;
        top: 10px;
    }

    .print-button {
        position: absolute;
        right: 0%;
        top: 45px;
        width: 16%;
    }

    .generate-bill-button {
        position: absolute;
        right: 0%;
        top: 47px;
        width: 16%;
    }

    .accessory-list {
        .print-block {
            overflow: hidden;
            // height: 0;
            // width: 0;
            display: none;
        }
        .no-print-block {
            .selected-list {
                .ant-typography {
                    display: block !important;
                }
                .ant-tabs {
                    @media only screen and (max-width: 768px) {
                        overflow: scroll !important;
                    }
                    .ant-tabs-nav {
                        display: block !important;
                    }
                    .ant-tabs-content-holder {
                        .ant-tabs-content {
                            .ant-tabs-tabpane {
                                .ant-table-wrapper {
                                    // padding: 0px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.generate-settlement {
    display: flex;

    .invoice-container {
        display: "flex";
        flex-direction: "column";
        align-items: "flex-start";
        width: 75%;
        // flex-basis: 70%;
    }

    .modal-footer {
        // flex-grow: 1;
        margin: 10px;
    }

    .ant-table-cell {
        white-space: nowrap;
    }
}