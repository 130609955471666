.request-overview {
    .page-header {
        margin: 10px;
    }

    .page-filter {
        margin: 10px;
    }

    .main-content {
        display: flex;

        .list {
            flex-basis: 25%;
            // width: 300px;
            // min-width: 245px;

            // max-width: 245px;
            // max-height: 2200px;
            // overflow-y: scroll;
            margin: 5px 2px 10px 2px;

            .list-subheader {
                margin-left: 10px;
            }

            .list-card {
                // margin: 30px 0px;

                border: 1px solid #f3f3f3;

                &.active {
                    box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);

                    // box-shadow: 1px 0 8px 0 rgb(0 0 0 / 5%), 8px 8px 18px 0 rgb(0 0 0 / 5%);
                    border: 1px solid #3faaff;
                    // box-shadow: 2px 0px 5px 3px #e8f7ff;

                    .card {
                        border-color: #c6c6c6;
                        background-color: #c6c6c694;
                    }
                }
            }

            .request-card {
                padding: 20px 10px 5px;
                cursor: pointer;
                .card {
                    box-shadow: 10px 0 5px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);

                    &:hover {
                        box-shadow: 10px 0 5px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
                        border-color: #cacaca;
                    }
                }
                &.active {
                    .card {
                        border-color: #c6c6c6;
                        background-color: #c6c6c694;
                    }
                }
            }
        }

        .list-detail {
            flex-basis: 75%;
        }
    }

    .page-filter {
        .filter {
            display: flex;
            padding: 16px 0px;
            .category {
                margin-right: 32px;
            }
        }
    }

    .list-detail {
        // padding: 5px 20px 5px 5px !important;
        .request-detail {
            .page-content {
                .ant-card {
                    margin-left: 19px !important;
                }
                .split-container {
                    .right {
                        .summary {
                            .vehicle-card {
                                .display-block {
                                    .breakup {
                                        .ant-card {
                                            .ant-card-body {
                                                padding: 0px !important;
                                                .detail-wrapper {
                                                    .detail-element {
                                                        margin: 0px !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
