.bill-preview-page {
    .bill-header {
        display: flex;

        .customer-info {
            display: flex;

            .detail-wrapper {
                display: flex;
                flex-grow: 1;
                margin: 0px 20px;
                gap: 30px;
            }
        }
    }
}
