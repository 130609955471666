.matching-bookings {
  // margin: 0px 10px;
  .vehicle-list {
    box-shadow: inset 0px 0px 4px 1px whitesmoke;
    margin: 4px 0px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;

    .vehicle-card{
      padding: 10px 15px;
      border-bottom: 1px solid #e8e8e8;

    }
  }



  .booking-card {
  
    h3.variant{
      display: inline-block;
    }
 
    p.recent-remarks{
      display: inline-block;
    }

  }
}
