.insurance-tracker {
    .header {
        display: flex;
        justify-content: flex-end;
    }

    .page-header {
        .right {
            display: flex;
        }
    }

    .header-actions {
        display: flex;
    }

    .ant-table-wrapper {
        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    .ant-table-container {
                        .ant-table-content {
                            .ant-table-tbody {
                                .ant-table-row {
                                    .ant-table-cell {
                                        .insurance-block {
                                            .ant-tag {
                                                font-size: 8px !important;
                                                line-height: 13px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}