.rto-form {
  margin: 0px 10px;
  .attachments {
    display: flex;
    // justify-content: space-between;

    .elements {
      margin: 20px 40px 0px 0px;
    }
  }
}
