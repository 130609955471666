.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .edit-div {
        display: flex;
    }
}

.vehicle-invoice {
    width: 100%;
    display: flex;
    flex-direction: column;


    // @media screen and (max-size: 768px){
    // }



    p {
        margin-bottom: 0.5em;
        color: #000;
    }

    .headline {
        font-size: 18px;
        text-align: center;
        padding: 5px;
        border-bottom: 1px solid #00000054;
        border-top: 1px solid #00000054;
        width: 100%;
    }

    .bill-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        p {
            font-size: 13px;
            margin-bottom: 0;
            text-align: right;
        }

        .logo-welcome {
            width: 160px;
        }
    }

    .bill-details {
        table {
            border: none !important;
        }

        font-weight: 400;
        display: flex;
        gap: 5%;
        flex-direction: row;
        justify-content: space-between;

        .detail-element {
            font-weight: 500;
            color: #000;
        }

        .left {
            align-items: flex-start;
            flex-grow: 2;
            flex-basis: 50%;
        }

        .right {
            align-items: flex-end;
            flex-grow: 1;
        }
    }

    .bill-content,
    .bill-summary {
        margin-top: 30px;
        width: 100%;
        border: none !important;

        td {
            padding: 5px 0;
            font-size: 16px;

            &.amount {
                text-align: right;
            }
        }

        .title {
            text-align: left;
            border-bottom: 1px solid #00000054;
            border-top: 1px solid #00000054;
            width: 100%;
            font-weight: 600;
            color: #000;
            font-size: 14px;
            padding: 5px;
        }
    }

    .vehicle-particulars {
        font-weight: 600;
        padding-top: 30px;

        table {
            width: 100%;

            td {
                border: 1px solid #00000054;
            }
        }

        .details-box {
            display: flex;
            flex-direction: column;
            padding: 10px;
            text-align: center;
            color: #000;
        }
    }

    .bill-summary {
        border: none !important;

        .text-right {
            text-align: right;
        }

        .grand-title {
            margin: 5px 0;
            font-size: 16px;
            font-weight: 600;
        }

        .grand-content {
            margin: 5px 0;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .footer {
        padding-top: 40px;

        .signature {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .sign-box {
            height: 60px;
            border-bottom: 1px solid #000;
            padding: 25px;
            margin-bottom: 19px;
        }

        table {
            width: 100%;
            align-items: center;
            text-align: center;
        }
    }
}