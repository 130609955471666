.request-card {
    width: 100%;
    .card {
      padding: 20px;
      box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
      margin: 15px 0px;
      border: 1px solid #efefef;
      color: #49535d;
      &:first-child {
        margin-top: 0;
      }
      
      .address {
        font-size: 14px;
        margin-bottom: 0px;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
      }
      .report-info {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          width: 50%;
          padding: 5px 0;
        }
      }
    }
  }