.rangepicker {
    input {
        padding: 4px;
        margin: 4px;
        min-width: 180px;
        border-radius: 4px;
        border: 1px solid #b9b8b8;
        &:focus,
        &:active {
            border: 1px solid #b9b8b8;
        }
    }
}
