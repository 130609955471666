.profile {
    margin: 20px;

    .profile-headline {
        display: flex;
        justify-content: flex-start;
        // justify-content: space-between;
        align-items: flex-start;

        .ant-typography {
            margin: 0px 20px;
        }

        .profile-picture {
            width: 100px;
        }

        .avatar-selector {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #3a9bff;

            label {
                margin: 4px 0px;
            }
        }
    }

    .section-content {
        .section-row {
            margin: 20px 0px;
        }
    }

    .profile-content {
    }
}
