.block-vehicle {
  .list-item {
    display: flex;
    justify-content: space-between;
    &:hover {
      background-color: whitesmoke;
    }
  }
}

.list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid;
  border-color: white;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-radius: 4px;
    border-color: #acdeff;
    border: 1px solid whitesmoke;
    background-color: aliceblue;
  }
  &.active{
    border: 1px solid #93c8ff;
    background: aliceblue;
  }
}
