.review-request-detail {
    // display: flex;
    // margin-top: 20px;
    margin: 0px 10px;



    .detail-element{
        margin: 10px 0px;
    }

  

  
    .alert-wrapper{
        margin-bottom: 16px;
        transition: all 0.5s ease;
    }
    .detail-wrapper{   
        margin-bottom: 16px; 
        box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); //7.009px 7.009px 5.006px rgba(175,194,203,.251)
        border-radius: 3px;
        padding: 15px 15px 8px;
        h3{
            font-weight: 700;
            font-size: 14px;
            color: #000;
            span{
                font-weight:400;
                margin-left: 5px;
                &.event{
                    font-weight: 600;
                }
            }
        }
        p{
            font-size: 16px;
            font-weight: 700;
            color: #000;
        }
        .ant-ag{
            font-size: 14px;
            padding: 2px 10px;
        }
    }
    .page-content{
        .page-heading{
            font-size: 20px;
        }
    }
}