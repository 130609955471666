.app {
  .content {
    padding: 10px 4%;

    @media only screen and (min-width: 768px) {
      padding: 10px 15px;
    }

    font-family: "Roboto";
    font-size: 14px;
    color: #49535d;
    line-height: 16px;
  }
}
.skeleton-wrapper {
  margin: 0px 0 20px 0;
  .card {
    padding: 20px;
    box-shadow:
      1px 0 8px 0 rgba(0, 0, 0, 0.05),
      8px 8px 18px 0 rgba(0, 0, 0, 0.05);
    // margin: 15px 0px;
    border: 1px solid #efefef;
    border-radius: 6px;
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 75% !important;
          height: 18px;
          margin-top: 0px;
          margin-bottom: 10px;
        }
        .ant-skeleton-paragraph {
          margin-right: 0;
          margin-top: 15px;
          margin-bottom: 0px;
          li {
            &:last-child {
              margin-top: 10px;
            }
          }
          > li {
            width: 100% !important;
            height: 15px;
            + li {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  .detail-skeleton-wrapper {
    .title {
      .ant-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            width: 100% !important;
            height: 25px;
            margin-bottom: 5px;
          }
          .ant-skeleton-paragraph {
            margin-top: 15px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .card {
      margin-top: 10px;
      .ant-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            width: 100% !important;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.score {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .score-card {
    // display: flex;
    // justify-content: space-between;

    // display: flex;
    // justify-content: space-between;
    // width: 100%;
    // border: 1px solid whitesmoke;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid whitesmoke;
    margin: 3px 3px;
    padding: 8px 8px;
    box-shadow: 0px 1px 3px #d6d6d6;

    p {
      margin: 0px;
    }

    .caption {
      color: black;
    }
    .value {
      // color: 10px;
    }
  }

  // flex-direction: row;
  .dashboard-item {
    width: 100%;
    height: 30px;
    margin: 10px 0px;
    // margin-right: 15px;
    margin-bottom: 25px;

    &.card:first-child {
      // margin-top: 15px;

      @media only screen and (max-width: 768px) {
        // margin-top: 0px;
      }
    }

    // margin: 5px;

    @media only screen and (max-width: 768px) {
      // margin: 5px;
    }

    .ant-statistic-title {
      text-transform: capitalize;
    }

    width: 100%;
    height: 30px;
  }
}

.section-content {
  padding: 4px 0px;
  .section-row {
    display: flex;

    justify-content: space-between;

    margin: 10px 0px;
  }
}
