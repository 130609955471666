.rm-pending-requests-container{
    display: flex;
    flex-wrap: wrap;
    .dashboard-item{
        width: 150px !important;
        height: 150px !important;
        margin: 10px 0px;
        width: 100%;
        height: 110px;
        margin-right: 15px;
        margin-bottom: 25px;
        position: relative;
        &:first-child{
            margin-top: 10px;
        }

        &:last-child{
            margin-right: 0s;
            .ant-statistic-content-value-int{
                display: none;
            }
            a{
                position: absolute;
                bottom: 3.5em !important; 
            }
        }
       
    }
}
@media screen and (max-size: 768px){
    .pending-requests-container{
        .dashboard-item{
            width: 100%;
        }
    }
}