.dashboard-container {
  // padding: 0px 10px;

  margin: 15px;

  .dashboard-content {
    // display: grid;
    // grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    .left{
      flex-grow: 1; 
      display: flex;
      flex-wrap: wrap;

      @media only screen and (max-width: 768px) {
        display: inline;
    }

      .summary-content{
        // margin: 15px;
      }

      .second-content {
        margin-right: 15px;
      }
    }

    .right {
      flex-basis: 50%;
      overflow: scroll;

      // display: flex;

      .calendar-card {
        flex-basis: 100%;


        @media only screen and (max-width: 768px) {

          .rbc-toolbar{
            justify-content: flex-start;

            flex-direction: column;
            .rbc-btn-group{
              margin: 10px 0px;
            }

          }


          .rbc-btn-group{

          }


        }


      }
    }
  }

 
  .score-pending-collections{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
    min-width: 189px;
    height: auto;
    padding: 10px;
    box-shadow: 1px 1px aliceblue;
    border: 1px solid #d6d6d6;
    border-radius: 9px;
    
    .dashboard-item {
      cursor: pointer;
      .card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .pending-collections-overview{
        display: flex;
        width: 100%;
        height: 5px;
        justify-content: space-between;
        align-items: center;
      } & p{
        margin-top: 15px;
      }
      
      .value{
        color: #4CA8FF;
      }

      .ant-statistic-title {
        text-transform: capitalize;
      }
    }
    @media only screen and (max-width: 768px) {
      .dashboard-item .card{
        width: 100%;
      }
    }
  }
}
