.task-overview {
    .list-headear {
        text-transform: capitalize;
    }

    .task-overview-content {
        display: flex;
        flex-wrap: wrap;
        .task-overview-item {
            width: 200px;
            min-width: 189px;
            padding: 10px;
            box-shadow: 1px 1px aliceblue;
            border: 1px solid #d6d6d6;
            border-radius: 9px;
            margin: 5px 13px;
            margin-left: 13px;
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 768px) {
        .task-overview-content .task-overview-item{
            width: 100%;
        }
      }

    .list-subheader {
        .ant-typography {
            text-transform: capitalize;
            font-size: 13px;
            color: #844b4b;
        }
    }
}
