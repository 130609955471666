.bank-upload {
    .selected-item-form {
        .selected-items {
            display: flex;
            flex-wrap: wrap;
        }

        .ant-select {
            margin: 10px 0px;
        }
    }

    .alert-message {
        margin: 10px 0px;
    }

    .ant-alert{
        margin: 10px 0px;

    }

    .page-header {
        .right {
            display: flex;
        }
    }

    .request-card {
        .ant-checkbox-wrapper {
            float: right;
        }
    }
}
