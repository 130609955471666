.user-authentication-section {
    min-height: 90vh;
    display: flex;
    margin: 0px 6%;
    // align-items: center;

    @media only screen and (min-width: 768px) {
        justify-content: center;
        align-items: center;
    }

    .homescreen {
        width: 100%;
        margin: 10px 0px;
        border-radius: 4px;
        background-color: aliceblue;
        padding: 50px;
        height: 40px;
    }

    .forgot-image {
        margin-left: 0px;

        @media only screen and (min-width: 768px) {
            width: 40%;
            height: 70%;
            margin: 0px;
            /* float: right; */
            position: absolute;
            right: 160px;
            top: 10%;
            object-fit: scale-down;
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .brand-logo {
        width: 120px;
        box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid #b7b7b7;
        padding: 4px;
        border-radius: 2px;
        margin: 20px 0px 30px;
    }
    .auth-form-wrapper {
        padding: 0px;
        width: 100%;
        box-sizing: border-box;
        @media only screen and (max-width: 768px) {
            padding: 0;
            // min-width: 275px;
        }
        .form-title {
            h3 {
                color: #071822;
                font-weight: 600;
                size: 18px;

                margin: 20px 0px;
                font-size: 32px;
            }
        }
        .ant-form {
            // height: 100%;
            .ant-form-item {
                margin: 30px 0px;
                .ant-form-item-label {
                    margin: 0 0 8px 0;
                    line-height: 0;
                    padding: 0;
                }
            }
            .ant-btn-primary {
                margin-top: 10px;
            }
        }
        .ant-form-explain {
            position: absolute;
            font-size: 12px;
            margin-left: 2px;
        }
        .ant-btn-primary {
            width: 20%;
            @media only screen and (max-width: 768px) {
                width: 100%;
                // min-width: 275px;
            }
        }
        .ant-btn-secondary {
            width: 100%;
        }
    }
}
