.if-status {
  margin: 40px 0px;
  .page-header {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .title {
      text-align: center;
    }
  }

  .page-content {
    .card {
      padding: 20px;
      box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
      margin: 15px 0px;
      border: 1px solid #efefef;
      color: #49535d;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .left {
        flex-basis: 20%;
      }
      .right {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;

        @media only screen and (max-width: 768px) {
          margin: 20px 0px;
          flex-direction: column;
        }

        .usage {
          width: 400px;
          @media only screen and (max-width: 768px) {
            width: 250px;
          }
  
        }

        .stats {
          display: flex;
          justify-content: space-around;
          flex-grow: 1;

          @media only screen and (max-width: 768px) {
            justify-content: space-between;

            margin: 20px 0px;
          }
        }
      }
      .city {
        text-transform: capitalize;
        margin-bottom: 20px;
      }
    }
  }
}
