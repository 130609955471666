.request-status {
    display: flex;

    .request-info {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 768px) {
            flex-direction: row-reverse;
        }

        .detail-wrapper {
            display: flex;
            flex-basis: 100%;
            flex-wrap: wrap;

            @media only screen and (max-width: 768px) {
                margin: 20px 0px;
            }

            .detail-element {
                width: 30%;

                @media only screen and (max-width: 768px) {
                    width: 40%;
                }
    
            }
        }
    }
}
