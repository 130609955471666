.enquiry-option {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    .enquiry {
        cursor: pointer; 
        padding: 14px;
        width: 300px;
        height: 150px;
        border-radius: 6px;
        border: 1px solid #ededed;
        margin-right: 15px;
        align-items: center;
        margin-bottom: 15px;
        justify-content: space-between;
        display: flex;
        flex-direction: row-reverse;

        &.active {
            box-shadow: 0px 1px 3px 0px #e5e5e5;
            border-color: #1a90ff9e;

            .anticon {
                color: #1a90ff9e;
            }
        }

        .anticon {
            color: #e0e0e0;
            font-size: 18px;
        }

        &:hover {
            box-shadow: 0px 1px 3px 0px #e5e5e5;
            border-color: #1a90ff9e;
        }
    }
}

