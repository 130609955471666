// Since this is in a modal
.collection-card {
  .ant-checkbox-wrapper {
    float: right;
  }
}

.pending-collections {
  .list-options {
    display: flex;
    justify-content: space-between;

    margin: 10px 0px;
    align-items: center;
  }
}

.reconcile-modal {
  .collection-card {
    display: flex;
    justify-content: space-between;
    margin: 22px 0px;
    flex-direction: column;
    .right {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;
    }
  }
  .selected-item-form {
    .selected-items {
      margin: 10px 0px;

      .ant-tag {
        white-space: nowrap;
      }
    }

    .form-actions {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      button {
        margin: 10px;
      }
    }
  }
}

.reconcile-summary {
  margin: 0px 10px;
  .receipt-card,
  .collection-card {
    .ant-checkbox-wrapper {
      float: right;
    }

    button {
      margin-top: 10px;
    }
  }

  .summary-content {
    .list-actions {
      button {
        margin: 0px 10px;
      }
    }

    .top-header {
      display: flex;
      justify-content: flex-end;
      .ant-select {
        min-width: 520px;
      }
    }

    .summary-listing {
      .selected-item-form {
        .selected-items {
          margin: 10px 0px;

          .ant-tag {
            white-space: nowrap;
          }
        }

        .form-actions {
          display: flex;
          justify-content: flex-end;

          button {
            margin: 10px;
          }
        }
      }
    }

    .receipt-card {
      .matching {
        background: whitesmoke;
        border: 1px solid #e2e2e2;
        padding: 10px;
        border-radius: 4px;
        margin: 15px 0px;

        .request-card {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
          .right {
            display: flex;
            align-items: center;
            button {
              margin: 0px 10px;
            }
            h3 {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
