.receipt-print{
    float: right;
    padding:10px;
    
}

.customer-receipt {
    overflow: hidden;
    height: 0;
    width: 0;
    display: none;
    .card-shadow.card {
        padding: 20px;
        box-shadow: 0;
        width:300px;
        margin: 0;
        border: 0;
        color: #ffffff;
    }
    
}

.bill-preview{
    display: flex;
    margin-right: 30%;
    flex-direction: row; 
    p{
        margin-bottom: .5em;
        color: #000;
    }
    .page-header{
        justify-content: center;
        font-size: 20px;
    }
    
    .bill-header{
        // display: flex;
        display:flex;
        flex-direction:row;
        justify-content: space-between; 
        align-Items: center;
        width: 100%;
        margin: 20px 0;
        .brand-logo {
            width: 120px;
            // border-radius: 2px;
            padding-bottom: 20px;
            img{
                width: 100%;
                margin: 0px;
            }
        }
        p{
            font-size: 12px;
            margin-bottom: 0;
            text-align: right;
            color: #000;
        }
    }
    .bill-details{
        font-weight: 400;
        display: flex;
        flex-direction: row;
        margin: 20px 0;
        justify-content: space-between;
        border: none;
        table{
            border:none !important;
            td{
            
                font-size: 14px;
                padding: 8px 8px;
            }
        }
    }

    .solidline{
        border-top:dashed 1px grey;
    }
    
    
    .bill-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 25px;
    }
    
}



