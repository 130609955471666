.actual-stock {
  margin: 0px 10px;
}

.stock-status, .current-stock {
  // margin: 0px 10px;
  .collapse-parent {
    // padding: 20px;
    box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
    margin: 15px 0px;
    border: 1px solid #efefef;
    color: #49535d;

    .ant-collapse-content-box {
      padding: 0px !important;
      > .ant-collapse {
        border: none;

        .stock-item {
          display: flex;

          padding: 12px 20px;
          justify-content: space-between;
          color: black;
          cursor: pointer;
          &:hover {
            background-color: whitesmoke;
          }
          .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h4 {
              margin-bottom: 0px;
              font-size: 14px;
              span {
                font-weight: 400;
                /* text-transform: lowercase; */
                color: #4e1919;
                font-size: 12px;
              }
            }
            .status {
              text-transform: uppercase;
            }
            .customer-requests {
            }
          }
          .right {
          }
        }
      }
    }
  }

  img.vehicle-img {
    width: 100px;
  }

  .vehicle-card {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid gainsboro;
    p {
      margin-bottom: 4px;
    }
  }

  .vehicle-header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    img {
      width: 200px;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      align-items: flex-start;
      display: flex;

      img {
        width: 150px;
      }

      .score-board {
        flex-direction: column;
      }
    }

    .score-board {
      display: flex;
      flex-grow: 1;
      text-align: center;
      // margin: 0px 20px;
    }
    .score-card {
      flex-grow: 1;
    }
    h4 {
      font-size: 20px;
      margin: 0px 20px;
    }
  }
}
