.fund-gap {
  .upload-section {
    display: flex;
    margin: 10px 0px;

    .upload-card {
      width: 300px;

      height: 160px;
      h4 {
        text-transform: capitalize;
      }

      .icon {
        margin: 10px 0px;
      }
    }
  }

  .form-container{
    margin: 20px 0px;
  }

  .card {
    border: 1px solid whitesmoke;
    border-radius: 4px;
    margin-right: 10px;
    padding: 20px 20px;
    box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
  }

  section {
    margin: 20px 0px;

    .basic-price {
      display: flex;
      .price-card {
        width: 300px;
      }
    }
  }
  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-title{
      display: flex;
      
    }
    .check-icon {
      width: 40px;
    }
    .panel-title {
      flex-basis: 15%;
      h1 {
        margin-bottom: 0px;
        text-transform: capitalize;
      }
    }
    .price-total {
      h1 {
        font-size: 25px;
        font-weight: bold;
      }
    }
  }

  .split-container{
    display: flex;
    .left{
      flex-basis: 58%;
    }
    >.right{
      margin: 20px;

      .summary{

        .right{
          .stats{
            margin: 35px 0px;
          }
        }

      }
    }
  }
}
