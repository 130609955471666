.landing {

  .list-header{
    display: flex;
    justify-content: space-between;
  }
    .brand-logo {
      img {
        width: 150px;
      }
    }
    .report-listing-wrapper {
        display: inline;
      .skeleton-wrapper{
        width: 100%;
      }
      .report-item {
        width: 100%;
        .card {
          padding: 20px;
          box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
          margin: 15px 0px;
          border: 1px solid #efefef;
          color: #49535d;
          &:first-child {
            margin-top: 10px;
          }
          
          .address {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 16px;
          }
          .report-info {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              display: inline-block;
              width: 50%;
              padding: 5px 0;
            }
          }
        }
      }
    }
    .about-us {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
      .header-blk {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .page-heading {
          margin: 2px 0 0 0;
          font-size: 24px;
          font-weight: 700;
          line-height: 26px;
        }
      }
      .page-desc {
        margin-bottom: 10px;
      }
    }
    .generic-header {
      // width: unset;
      // margin-top: 6px;
      // float: right;
    }
    .add-new-record-btn {
      padding: 8px;
      border: 1px solid;
      background: #fff;
      text-align: center;
      width: 80px;
      cursor: pointer;
      color: #1890ff;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 2px #dfe7f1;
    }
    .pagination-wrapper {
      margin: 15px 0;
      .ant-pagination {
        text-align: center;
      }
    }
  }
  