.vehicle-detail {
    .stock-transfer-tab {
        width: 800px;
        .section {
            width: auto;
            display: flex;
            flex-direction: row;
            gap: 89px;
        }
    }
    .page-content {
        display: flex;

        // .right-content {
        //     flex-grow: 1;
        // }

        .vehicle-thumbnail {
            h5 {
                i {
                    font-weight: 400;
                    font-style: normal;
                    font-size: 12px;
                    color: brown;
                    display: block;
                }
            }

            .stock-transfer {
                margin-top: 20px;
            }

            .adjust-stock{
                margin-top: 20px;

            }
            // display: flex;
            // justify-content: space-between;

            @media only screen and (min-width: 768px) {
                width: 300px;
            }

            margin-right: 10px;

            img {
                width: 100%;

                @media only screen and (min-width: 768px) {
                    width: 120px;
                }
                width: 100px;
            }
            // .thumbnail {
            //     margin-bottom: 10px;
            // }

            // h4.ant-typography {
            //     margin: 0px;
            // }
            // .size-hint {
            //     margin-bottom: 10px;
            // }
        }
        .right-content {
            .request-card {
                .card {
                    width: 900px;
                }
            }
        }
    }
}
