.header {
    cursor: pointer;
    // padding: 10px;
    // border-radius: 4px;
    // border: 1px solid #dcdcdc;
    margin-right: 10px;
    &:hover {
      border-color: #c1c1c1;
    }

    .back-icon{
      .fas {
        font-size: 18px;
        color: #000000;
      }
    }
  }
  