// Notice Board

.notice-board {
    .welcome-block {
        display: flex;
        // border: 1px solid rgb(239, 239, 239);
        padding: 15px;
        width: 50%;
        border-radius: 4px;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        .content-block {
            display: "flex";
            flex-direction: "column";

            h2.ant-typography {
                color: #228c48;
            }
            h3.ant-typography {
                margin-top: 0px;
            }

            .sub-title {
                @media only screen and (max-width: 768px) {
                    font-size: 12px;
                }
            }
        }
    }

    .change-password-notice {
        @media only screen and (min-width: 768px) {
            max-width: 50%;
        }

        // max-width: 50%;

        background-color: black;
        border-radius: 4px;
        /* margin: 0px; */
        background: #161f26;
        border: none;
        margin: 14px 0px;
        color: black;
        box-shadow: 0px 0px 4px 2px #6f6f6f70;

        h3 {
            display: inline;
            font-size: 18px;
            color: orange;
        }

        h5.ant-typography {
            color: #22c822;
        }

        p {
            color: white;
            font-size: 14px;
        }
    }
}
