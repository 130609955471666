.accessory-form {
  margin: 0px 10px;
  .detail-element {
    margin: 10px 0px;
    span {
      margin-bottom: 10px;
    }
  }

  .detail-wrapper {
    margin-bottom: 16px;
    // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); //7.009px 7.009px 5.006px rgba(175,194,203,.251)
    border-radius: 3px;
    // padding: 15px 15px 8px;
    h3 {
      font-weight: 700;
      font-size: 14px;
      color: #000;
      span {
        font-weight: 400;
        margin-left: 5px;
        &.event {
          font-weight: 600;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }
    .ant-ag {
      font-size: 14px;
      padding: 2px 10px;
    }
  }
  .page-content {
    .page-heading {
      margin: 20px 0px;
      font-size: 20px;
    }
  }

  .page-content {
    .split-container {
      display: flex;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      .vehicle-card {
        margin-bottom: 20px;
        padding: 20px;
        // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        // margin: 15px 0;
        border: 1px solid #efefef;
        color: #49535d;

        .detail-wrapper {
          .detail-element {
            margin: 20px 0px;
            h3 {
              margin: 10px 0px;
            }
          }
        }
        .document-status {
          margin: 10px 0px;
        }
      }

      > .left {
        width: 300px;

        @media only screen and (max-width: 768px) {
        }
      }
      .right {
        margin: 0px 0px 0px 20px;
        flex-grow: 1;
        color: #49535d;
      }
    }
  }
}

.accessory-choices {
  .accessory-list {
    .page-actions {
      float: right;
    }

    // Custom CSS for Invoice Print File
    @media print {
      .ant-table-cell:last-child {
        // display: none;
      }
      tr {
        td {
          &.ant-table-cell:last-child {
            // display: none;
          }
        }
      }
    }

    .ant-alert {
      margin: 20px 0px;
    }

    .ant-table-wrapper {
      margin-top: 10px;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    // height: initial !important;
    // overflow: initial !important;
    // -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    // margin-top: 1rem;
    // display: block;
    // page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20px;
}
