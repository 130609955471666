
.manage-requests{



  .page-header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4{
      text-transform: capitalize;
      margin: 0px 0px 20px;
    }

  
  }

  .ant-card{
    .ant-card-body{
      padding: 0px;
    }
  }


}