.current-stock {
    .page-header {
        display: flex;
        justify-content: space-between;
    }



    .ant-collapse-content-box{
        .ant-alert{
            margin: 8px;
        }
    }
}


