.order-tracking {
    .tracking-component{
        .ant-table-wrapper{
            .ant-spin-nested-loading{
                .ant-spin-container{
                    .ant-table {
                        .ant-table-container{
                            .ant-table-content{
                                .ant-table-thead{
                                    .ant-table-cell{
                                        padding: 4px 4px !important;
                                    }
                                }
                                .ant-table-tbody{
                                    .ant-table-row {
                                        .ant-table-cell{
                                            padding: 4px 4px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}