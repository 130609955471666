.daily-overview {
    .graph-container {
        height: 300px;
    }

    .page-header {
        display: flex;
        flex-direction: column;
        align-items: unset;

        .page-actions{
            display: flex;
            justify-content: flex-end;
            // justify-content: space-between;
        }
    }

    .ant-card {
        margin: 10px 0px;
    }

    table {
        th {
            // white-space:nowrap;
            // padding: 8px 20px;
        }
        td {
            // border-color: brown;
        }
        .calicut {
            background: aliceblue !important;
        }

        .kannur {
            background: #fff0f0 !important;
        }
        .malappuram {
            background: #e1fbe1 !important;
        }
        .kondotty {
            background: #efd6ea !important;
        }
        .tirur {
            background: #c3e4fe !important;
        }
        .vadakara {
            background: #f7f7c1 !important;
        }
        .kasargod{
            background: #f7dcbe !important;
        }
        .total {
            background: #fbf4e1 !important;
        }
    }
}
