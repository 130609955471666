.landing {

  // margin: 0px 10px;

    .datepicker{
        margin-right: 1em !important;
    }
    .list-header{
      display: flex;
      justify-content: space-between;
    }
    .brand-logo {
      img {
        width: 150px;
      }
    }

    .delivery-list{
      display: flex;
    }
    .report-listing-wrapper {
  
      .skeleton-wrapper{
        width: 100%;
      }
   
    }
    .about-us {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
  
  
      .page-menu{
        display: flex;
        align-items: center;
      }
     
  
      .header-blk {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .page-heading {
          margin: 2px 0 0 0;
          font-size: 24px;
          font-weight: 700;
          line-height: 26px;
        }
      }
      .page-desc {
        margin-bottom: 10px;
      }
    }
    .generic-header {
      // width: unset;
      // margin-top: 6px;
      // float: right;
    }
    .add-new-record-btn {
      padding: 8px;
      border: 1px solid;
      background: #fff;
      text-align: center;
      width: 80px;
      cursor: pointer;
      color: #1890ff;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 2px #dfe7f1;
    }
    .pagination-wrapper {
      margin: 15px 0;
      .ant-pagination {
        text-align: center;
      }
    }
  }
  