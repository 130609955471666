.spotlight-search
{
    .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    &:focus {
        outline: none;
    }
    }
    .ant-modal-body {
    padding: 0px !important;
    background-color: transparent;
    }

    .search-box {
    border-radius: 0.5rem !important;
    border: none !important;
    padding: 0.8em 1.2em !important;
    }

    .show-search{
    margin-right: 1em;
    }

    .ant-modal-close {
    display: none !important;
    }
    .ant-card {
    border-radius: 0em 0em 0.5em 0.5em !important;
    }
    .ant-modal-footer {
    display: none;
    }
    .bottom-border-no-radius {
    border-radius: 0.5em 0.5em 0em 0em !important;
    }
    .visible-item {
    display: block;
    }
    .hidden-item {
    display: none;
    }
    .spotlight-container {
    display: flex;
    flex-direction: column;
    }
    .spotlight-search-tip {
    position: absolute;
    width: 500px;
    left: 0;
    top: 4.5em;
    font-weight: bold;
    color: #fff;
    }
    .ant-card-body {
    padding-top: 0.5em !important;
    }
    .results-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        font-weight: bold;
        padding: 0.5em 0em 0.5em 0;
        border-bottom: 1px solid #f5f5f5;
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:last-child {
        border: none;
        padding-bottom: 0;
        }
    }
    }
    .light-text {
    color: grey;
    font-weight: bold;
    }
}