.customer-receipts {
  margin: 0px 10px;
  .score {
    display: flex;
    margin: 10px 0px ;
    .dashboard-item {
      margin-right: 10px;

      .card{
        min-width: 120px;

      }

      .ant-statistic-title {
        text-transform: capitalize;
      }
    }
  }

  .list-header {
    .page-desc {
      .ant-picker-range {
        margin: 0px 10px;
      }
    }

    .list-actions {
      button {
        margin: 0px 10px;
      }
    }
  }

  .score {
  }


    // flex-direction: row;
    .dashboard-item {
      width: 150px !important;
      height: 150px !important;
      margin: 10px 0px;
      width: 100%;
      height: 110px;
      margin-right: 15px;
      margin-bottom: 25px;

      &.card:first-child {
        // margin-top: 15px;

        @media only screen and (max-width: 768px) {
          // margin-top: 0px;
        }
      }

      // margin: 5px;

      @media only screen and (max-width: 768px) {
        margin: 5px;
      }

      .ant-statistic-title {
        text-transform: capitalize;
      }

      width: 100%;
      height: 110px;
    }
}
