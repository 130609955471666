.accounts-overview {
  margin-top: 20px;

  .page-header {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: unset !important;

      .page-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .score {
    display: flex;
  }

  .page-actions {
    button {
      margin: 0px 10px;

      @media only screen and (max-width: 768px) {
        margin: 0px;
      }
    }

    @media only screen and (max-width: 768px) {
      

      .ant-select{
        margin: 5px 0px;
      }

      .ant-picker{
        margin: 5px 0px;
      }


    }
  }

  .ant-table-container {
    .ant-table-content {
      table {
        thead {
          tr {
            th:nth-child(2) {
              width: 35px;
            }
            th:last-child {
              width: 80px;
            }
          }
        }
      }
    }
  }

  .ant-table-body > tr > td {
    padding: 8px 2px;
  }

  //  .card {
  //   margin: 6px;
  //   width: 150px;
  //   height: 150px;
  //   padding: 10px;
  //   display: flex;
  //   justify-content: space-between;
  // flex-direction: column;

  // }

  .ant-card {
    margin: 10px 0px;
  }
}
