
.reconcilation-overview {
    .list-headear {
        text-transform: capitalize;
    }

    .reconcilation-overview-content {
        display: flex;
        flex-wrap: wrap;
        .reconcilation-overview-item {
            width: 200px;
            padding: 10px;
            box-shadow: 1px 1px aliceblue;
            border: 1px solid #d6d6d6;
            border-radius: 9px;
            margin: 5px 13px;
            margin-left: 13px;
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 768px) {
    .reconcilation-overview-content .reconcilation-overview-item{
            width: 100%;
            min-width: 100%;
        }
      }
}


