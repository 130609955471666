.vehicle-overview{

    .page-header{

        .right{
            display: flex;
        }
    }

    .ant-table-wrapper{
        .ant-spin-nested-loading{
            .ant-spin-container{
                .ant-table {
                    .ant-table-container{
                        .ant-table-content{
                            .ant-table-tbody{
                                .ant-table-row {
                                    .ant-table-cell{
                                        .insurance-block{
                                            .ant-tag {
                                                font-size: 8px !important;
                                                line-height: 13px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}