body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.title {
    // font-size: 16px;
    // font-weight: 600;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    line-height: 18px;

    &.amount {
        color: red;
    }

    &.credit {
        color: green;
    }
}

img {
    // width: 100%;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.ant-card-body {
    padding: 15px !important;
}

// Common CSS Components

// For Request Card
.request-card {
    width: 100%;
    .card {
        padding: 20px;
        box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        // margin: 15px 0px;
        border: 1px solid #efefef;
        color: #49535d;
        &:first-child {
            margin-top: 0;
        }

        .ant-tag {
            float: right;
        }

        .address {
            font-size: 14px;
            margin-bottom: 0px;
            line-height: 16px;
            display: flex;
            justify-content: space-between;
        }
        .report-info {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                width: 50%;
                padding: 5px 0;
            }
        }
    }
}

// For Booking Card
.booking-card {
    width: 100%;
    .card {
        padding: 20px;
        box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        margin: 15px 0px;
        border: 1px solid #efefef;
        color: #49535d;
        &:first-child {
            margin-top: 0;
        }

        .address {
            font-size: 14px;
            margin-bottom: 0px;
            line-height: 16px;
            display: flex;
            // justify-content: space-between;
        }
        .report-info {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                width: 50%;
                padding: 5px 0;
            }
        }
    }
}

.card-action {
    margin: 0px 10px;
}

.tab-header {
    margin: 10px 0px;
}

.card-buttons {
    margin: 10px 0px;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .action {
        // align-self: flex-end;
    }
}

.page-content {
    width: 100%;
}

// Drawar Styles

.drawer-content .ant-drawer-content {
    .intro {
        padding: 14px;
        // background-color: aliceblue;
        border-bottom: 1px solid #e7e7e7;
        h4 {
            color: #05385a;
        }
        .locations {
            margin: 10px 0px;
        }
    }
}

.search-drawer {
    .intro {
        // padding: 14px;
        // background-color: aliceblue;
        h4 {
            color: #05385a;
        }
        .locations {
            margin: 10px 0px;
        }
    }
}

.stock-item {
    display: flex;

    padding: 12px 20px;
    justify-content: space-between;
    color: black;
    cursor: pointer;
    &:hover {
        background-color: whitesmoke;
    }
    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h4 {
            margin-bottom: 0px;
            font-size: 14px;
            span {
                font-weight: 400;
                /* text-transform: lowercase; */
                color: #4e1919;
                font-size: 12px;
            }
        }
        .status {
            text-transform: uppercase;
        }
        .customer-requests {
        }
    }
    .right {
    }
}

.ant-tabs-tab-btn {
    text-transform: capitalize;
}

// Sidemenu Styling

.logo-welcome {
    width: 100px;

    // padding: 10px;

    margin: 10px 0px;
    // height: 100px;
}

// Card Style

// #to do to be seperated to different file

.card {
    padding: 20px;
    box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #efefef;
    color: #49535d;

    .address {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
    }
    .report-info {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            width: 50%;
            padding: 5px 0;
        }
    }
}

.ant-btn {
    margin-right: 8px;
}

// Table Styles

.listing {
    margin: 10px 14px;
    .location {
        text-transform: capitalize;
    }
}

// Table Styles Ends

@media print {
    .noprint {
        display: none;
    }
    table {
        min-width: 98% !important;
        border: 1px solid #f0f0f0 !important;
        // min-height: 370px;
    }
    thead {
        // background-color: #f0f0f0 !important;
        tr {
            th {
                padding: 4px 4px !important;
                font-weight: 400 !important;
                font-size: 14px !important;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 4px 4px !important;
                font-size: 12px !important;
                .ant-tag {
                    font-size: 12px !important;
                    padding: 0 2px !important;
                }
            }
        }
    }
    tfoot {
        tr {
            td {
                padding: 4px 4px !important;
                font-size: 12px !important;
            }
        }
    }
}

.detail-wrapper {
    .detail-element {
        // margin: 20px 0px;
        h3 {
            margin: 10px 0px;
        }
    }
}

.ant-alert-message {
    text-transform: capitalize;
}
