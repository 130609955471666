.reports {
    // display: flex;
    // margin-top: 20px;
    .scanner{
        // min-height: 220px;
        video{
            height: 220px
        }
        div{
            box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 1px inset!important;
        }
    }
    .welcome{
        box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
        padding: 15px;
        border: 1px solid #dedede;
    }

    .detail-element{
        margin: 10px 0px;
    }

    .report-summary{
        display: flex;
        flex-direction: column;
        .report-card{
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            padding: 6px 0px;
            .report-item{

            }
        }
    }

    .intro {
        
        .first-line {
            box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);

            display: flex;
            align-items: baseline;

            p {
                margin: 0px 10px;
                font-size: 20px;
            }
          
        }

        h1.welcome {
            margin-top: 50px;
            font-size: 50px;
            color: #463737;
        }

        @media only screen and (min-width: 600px) {
            // display: flex;

            // justify-content: flex-start;

            h1.second-line {
                color: #7d7d7d;
                font-size: 70px;
            }
        }


    }


    .top-gallery {
        margin: 50px 0px;

        .card {
            padding: 25px 15px;
            box-shadow: 0px 0px 10px 10px #e2e2e2;

            
        }

        .carousel {
            .carousel-item {
                img {
                    width: 100%;
                }

                .carousel-caption {
                    h3 {
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }
                }
            }
        }
    }
    .locations-wrapper{
        padding: 15px 10px 10px;
        margin-bottom: 0px;
        .ant-steps{
            .ant-steps-item{
                padding-bottom: 10px;
                &:last-child{
                    padding-bottom: 0;
                }
                .step-progress{
                    font-weight: 600;
                    color: #000;
                    ul{
                        padding: 0;
                        margin: 10px 0;
                        list-style: none;
                        li{
                            display: inline-block;
                            margin-bottom: 5px;
                            margin-right: 10px;
                            p{
                                margin-bottom: 0;
                                font-weight: 400;
                                span{
                                    margin-left: 3px;
                                    font-weight: 600;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        &.scan{
            .loader-wrapper{
                position: absolute;
                top: 0;
                left: 43%;
            }
            section{
                opacity: 0.4;
            }
        }
    }
    .alert-wrapper{
        margin-bottom: 16px;
        transition: all 0.5s ease;
    }
    .detail-wrapper{   
        margin-bottom: 16px; 
        box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); //7.009px 7.009px 5.006px rgba(175,194,203,.251)
        border-radius: 3px;
        padding: 15px 15px 8px;
        h3{
            font-weight: 700;
            font-size: 14px;
            color: #000;
            span{
                font-weight:400;
                margin-left: 5px;
                &.event{
                    font-weight: 600;
                }
            }
        }
        p{
            font-size: 16px;
            font-weight: 700;
            color: #000;
        }
        .ant-ag{
            font-size: 14px;
            padding: 2px 10px;
        }
    }
    .page-content{
        .page-heading{
            font-size: 20px;
        }
    }
    .page-content {
        .split-container {
          display: flex;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
    
          .vehicle-card {
            margin-bottom: 20px;
            padding: 20px;
            // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
            // margin: 15px 0;
            border: 1px solid #efefef;
            color: #49535d;
    
            .detail-wrapper {
              .detail-element {
                margin: 20px 0px;
                h3 {
                  margin: 10px 0px;
                }
              }
            }
            .document-status{
              margin: 10px 0px;
            }
          }
    
          > .left {
            width: 300px;
    
            @media only screen and (max-width: 768px) {
            }
          }
          .right {
            margin: 0px 0px 0px 20px;
            flex-grow: 1;
            // padding: 20px;
            /* box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05); */
            // border: 1px solid #efefef;
            color: #49535d;
    
            .vehicle-card {
              // margin: 10px 0px;
            }
    
            .cancel-button{
              margin-left: 10px;
            }
          }
        }
      }
}