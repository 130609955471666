.summary {
    // background-color: yellowgreen;
    .vehicle-card {
        .display-block{
            .page-header{
            }
            .breakup{
                .table-card{
                    .detail-wrapper{
                        .detail-element{
                            .ant-table-wrapper{
                                .ant-spin-nested-loading{
                                    .ant-spin-container{
                                        .ant-table {
                                            .ant-table-container{
                                                .ant-table-content{
                                                    .ant-table-tbody{
                                                        .ant-table-row {
                                                            .ant-table-cell{
                                                                .ant-btn {
                                                                    display: initial !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .print-block {
        overflow: hidden;
        height: 0;
        width: 0;
        display: none;
    }

    .vehicle-card{
        padding: 10px!important;

    }

    .breakup-wrapper{
        display: flex;
        justify-content: space-between;
        .button-container{
            margin-top: 0.5em;
        }
    }

    .breakup {
        @media only screen and (min-width: 768px) {
            display: flex;
            flex-direction: row;
        }

        .table-card {
            border: 1px solid whitesmoke;
            // margin: 0px 10px;
            flex-basis: 50%;
            p{
                margin: 10px;
            }
        }
    }

    @media print {
        // background-color: yellow;

        .breakup {
            display: none;
            // display: flex;
            // display: block;
            // display: unset;

            .ant-card {
                display: none;
                // width: 48%;
                // display: inline-block;
                margin: 0px 10px;
            }

            .detail-wrapper {
                // display: inline-block;
            }
        }

        .print-block {
            display: block;
            .breakup {
                display: flex;
            }
        }
    }
}

.hello {
    display: flex;
    // background-color: yellow;
}

.print-block {
    .vehicle-card {
        padding: 5px 10px;
    }

    .page-header {
        // background-color: red;
    }

    @media print {

        font-size: 8px;
        // background-color: yellow;
    }
}
