.stock-pointer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the start of the container */
    gap: 10px; /* Adds spacing between radio buttons and select dropdown */
}

.stock-pointer .ant-radio-group {
    margin-bottom: 10px; /* Adds space below the radio group */
}

.stock-pointer .ant-select {
    width: 200px; /* Ensures the select dropdown stays the same width */
}